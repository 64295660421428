import React, { useState, useEffect } from 'react';
import api from './services/api';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import { Layout, Menu, Col, Row } from 'antd';
import CryptoCurrenciesList from './components/CryptoCurrenciesList';
import Settings from './components/Settings';
import Login from './components/Login';
import Disconnected from './components/Disconnected';
import LoginOk from './components/LoginOk';
import Logo from './assets/images/CryptoDash_MainLogo.webp';

const isAuthenticated = () => !!localStorage.getItem('user');

const { Header, Content } = Layout;

const App = () => {
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);

  const handleSaveApiKey = (apiKey) => {
    localStorage.setItem('cmcApiKey', apiKey);
    alert('Clé API sauvegardée avec succès !');
    console.log('API Key Saved:', apiKey);
  };

  const fetchCurrencies = async () => {
    try {
      const response = await api.get(`/crypto-currencies`);
      console.log('Mise à jour des données : ', response.data);
      setCryptoCurrencies(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des crypto-monnaies", error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const logout = () => {
    localStorage.removeItem('user'); // Supprime les données de l'utilisateur
    window.location.href = '/disconnected'; // Redirection vers la page de confirmation
  };

  const [current, setCurrent] = useState('1');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Router>
      <Layout className="layout">
        <Header>
            <Row>
              <Col span={5}>
                <div className="logo">
                  <img src={Logo} alt="Logo CryptoDash, portfolio de crypto-monnaies avec alertes de prises de bénéfices" />
                </div>
              </Col>
              <Col span={12} offset={7}>
                <Menu theme="dark" mode="horizontal" onClick={onClick} selectedKeys={[current]} items={[
                  { key: '1', label: (<Link to="/">Vos cryptos</Link>) },
                  { key: '2', label: 'Suivi financier' },
                  { key: '3', label: 'Contact' },
                  { key: '4', label: (<Link to="/settings">Paramètres</Link>) },
                  { key: '5', label: (<Link to="/disconnected"><a onClick={logout}>Déconnexion</a></Link>), style: { float: 'right' } }
                ]} />
              </Col>
            </Row>
        </Header>
        <Content style={{ padding: '0 50px', marginTop: '20px' }}>
          <Routes>
            <Route path="/" element={isAuthenticated() ? <CryptoCurrenciesList cryptoCurrencies={cryptoCurrencies} onCurrenciesUpdate={fetchCurrencies} /> : <Navigate to="/login" />} />
            <Route path="/settings" element={isAuthenticated() ? <Settings onSaveApiKey={handleSaveApiKey} /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/disconnected" element={<Disconnected />} />
            <Route path="/loginok" element={<LoginOk />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
};

export default App;
