import React, { useState } from 'react';
import { Form, Input, Button, Space, Typography, Col, Row, Card, message } from 'antd';
import api from '../services/api';

const { Text, Link, Title } = Typography;

const Settings = ({ onSaveApiKey }) => {
  const [apiKey, setApiKey] = useState('');

  const handleSubmit = async () => {
    try {
      await api.post(`/user-settings/cmc-api-key`, {
        cmcApiKey: apiKey,
      });
      message.success('Clé API enregistrée avec succès');
      onSaveApiKey(apiKey);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la clé API', error);
      message.error('Erreur lors de l\'enregistrement de la clé API');
    }
  };

  return (
    <Row>
      <Col span={22} offset={1}>
        <Title>Paramètres</Title>
        <Card style={{ padding: 20, marginBottom: 20 }}> 
          <Title level={2}>Votre clé API CoinMarketCap</Title>
          <Space
            direction="vertical"
            style={{ marginBottom: 20 }}>
          <Text>Cette clé API est accessible gratuitement suite à la création d'un compte sur <Link href='https://coinmarketcap.com/' target="_blank">CoinMarketCap</Link>.
            Une fois votre compte créé, vous aurez accès à un bouton "API" (en haut à droite de l'écran) où vous pourrez récupérer votre clé.</Text>
            <Text>Saisir cette clé dans le champ ci-dessous vous permettra de mettre à jour les prix actuels de vos crypto-monnaies dans la rubrique "Vos cryptos"</Text>
          </Space>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Title level={3}>Clé API CoinMarketCap</Title>
            <Form.Item>
              <Col span={14}>
                <Input  
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  placeholder="Saisissez votre clé API CoinMarketCap ici"
                />
              </Col>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Enregistrer
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Settings;
