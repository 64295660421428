import React, { useState, useEffect } from 'react';
import EditCryptoCurrencyForm from './EditCryptoCurrencyForm';
import AddCryptoCurrencyForm from './AddCryptoCurrencyForm';
import AddAlertForm from './AddAlertForm';
import { Table, Button, Tooltip, Modal, Space, Col, Row, Alert } from 'antd';
import { InfoCircleOutlined, AlertOutlined } from '@ant-design/icons';
import api from '../services/api';

const CryptoCurrenciesList = ({ cryptoCurrencies, onCurrenciesUpdate }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false); // État pour la modale d'ajout d'alerte
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [alerts, setAlerts] = useState({}); 

  useEffect(() => {
    const fetchAlerts = async () => {
      const alertsData = await Promise.all(cryptoCurrencies.map(async (currency) => {
        try {
          const response = await api.get(`/alerts/byCrypto/${currency.id}`);
          return { [currency.id]: response.data };
        } catch (error) {
          console.error("Erreur lors de la récupération des alertes pour la crypto-monnaie avec l'ID ", currency.id, error);
          return { [currency.id]: [] }; // Retourner un tableau vide en cas d'erreur pour éviter de bloquer l'interface utilisateur
        }
      }));
      setAlerts(alertsData.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };
  
    if (cryptoCurrencies.length > 0) {
      fetchAlerts();
    }
  }, [cryptoCurrencies]);

  const handleEditClick = (currency) => {
    setSelectedCurrency(currency);
    setIsEditModalOpen(true);
  };

  const handleAddAlertClick = (currency) => {
    setSelectedCurrency(currency);
    setIsAddAlertModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedCurrency(null);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleCloseAddAlertModal = () => {
    setIsAddAlertModalOpen(false); // Fermer la modale d'ajout d'alerte
  };

  const handleUpdateSuccess = () => {
    handleCloseEditModal();
    onCurrenciesUpdate();
  };

  const handleAddCurrencySuccess = (newCurrency) => {
    handleCloseAddModal();
    onCurrenciesUpdate();
  };

  const handleAddAlertSuccess = () => {
    // handleCloseAddAlertModal();
    onCurrenciesUpdate(); // Actualiser les données pour refléter l'ajout d'une nouvelle alerte
  };

  const [updateError, setUpdateError] = useState(null);

  const handleUpdatePrices = async () => {
    try {
      await api.get(`/crypto-currencies/updateAllPrices`);
      onCurrenciesUpdate();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des prix", error);
      setUpdateError('Impossible de mettre à jour les prix. Veuillez vérifier si votre clé API CoinMarketCap gratuite est enregistrée dans les Paramètres.');
    }
  };
  
  // Configuration des différentes couleurs du picto des alertes
  const getColorForAlertStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return '#52c41a'; // vert
      case 'TRIGGERED':
        return '#ff4d4f'; // rouge
      case 'INACTIVE':
        return 'grey'; // gris
      default:
        return 'inherit';
    }
  };

  // Calcul du nombre d'alertes actives et déclenchées
  const alertCounts = Object.values(alerts).flat().reduce((acc, alert) => {
    if (alert.status === 'ACTIVE') acc.active += 1;
    if (alert.status === 'TRIGGERED') acc.triggered += 1;
    return acc;
  }, { active: 0, triggered: 0 });

  // Configuration des colonnes pour le tableau Ant Design
  const columns = [
    {
      title: `Nom (${cryptoCurrencies.length} cryptos)`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => `${record.name} (${record.symbol})`,
    },    
    {
      title: 'Prix actuel',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text, record) => `$${record.price != null ? record.price.toFixed(2) : 'Non disponible'}`,
    },
    {
      title: 'ATH',
      dataIndex: 'ath',
      key: 'ath',
      sorter: (a, b) => a.ath - b.ath,
      render: (text, record) => `$${record.ath != null ? record.ath.toFixed(2) : 'Non spécifié'}`,
    },
    {
      title: 'Votre prix d\'achat moyen',
      dataIndex: 'averagePurchasePrice',
      key: 'averagePurchasePrice',
      sorter: (a, b) => a.averagePurchasePrice - b.averagePurchasePrice,
      render: (text, record) => `$${record.averagePurchasePrice != null ? record.averagePurchasePrice.toFixed(2) : 'Non spécifié'}`,
    },
    {
      title: (
        <div>
          Alertes
          <div style={{ marginTop: '8px' }}>
            <AlertOutlined style={{ color: '#52c41a', marginRight: 8 }} />
            {alertCounts.active}
            <AlertOutlined style={{ color: '#ff4d4f', marginLeft: '16px', marginRight: 8 }} />
            {alertCounts.triggered}
          </div>
        </div>
      ),
      key: 'alerts',
      render: (_, record) => {
        const currencyAlerts = alerts[record.id] || [];
        return currencyAlerts.map((alert, index) => (
          <Tooltip key={index} title={alert.name}>
            <AlertOutlined style={{ color: getColorForAlertStatus(alert.status), marginRight: 8 }} />
          </Tooltip>
        ));
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (_, record) => [
        <Tooltip title="Modifier cette crypto" key={`edit-${record.id}`}>
          <Button type="primary" ghost onClick={() => handleEditClick(record)} style={{ marginRight: 8 }}>
            Modifier
          </Button>
        </Tooltip>,
        <Tooltip title="Ajouter une alerte" key={`add-alert-${record.id}`}>
          <Button icon={<AlertOutlined />} onClick={() => handleAddAlertClick(record)} style={{ marginRight: 8 }}>
            Alertes
          </Button>
        </Tooltip>,
          <Tooltip title="Voir sur CoinMarketCap" key={`view-${record.id}`}>
          <a
            href={`https://coinmarketcap.com/currencies/${record.name.toLowerCase().replace(/\s+/g, '-')}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button style={{ color: '#3861FB', fontWeight: 'bold' }}>
              CMC
            </Button>
          </a>
        </Tooltip>,
      
      ],
    },
  ];

  return (
    <>
    {updateError && (
      <Alert
        message="Erreur de mise à jour"
        description={updateError}
        type="error"
        showIcon
        closable
        onClose={() => setUpdateError(null)}
        style={{ marginBottom: 16 }}
      />
      )}
      <Row>
        <Col span={22} offset={1}>
          <h1>Vos cryptos</h1>
          <Space style={{ marginBottom: 20 }}>
            <Button type="primary" onClick={() => setIsAddModalOpen(true)}>
              Ajouter une nouvelle crypto
            </Button>
            <Button onClick={handleUpdatePrices}>
              Mettre à jour les prix
            </Button>
          </Space>
          <Table columns={columns} dataSource={cryptoCurrencies} rowKey="id" pagination={false} bordered scroll={{ y: 600 }} style={{ marginBottom: 55 }}/>
          <Modal
            open={isEditModalOpen}
            onCancel={handleCloseEditModal}
            footer={null}
          >
            {selectedCurrency && (
              <EditCryptoCurrencyForm
                cryptoCurrency={selectedCurrency}
                onClose={handleCloseEditModal}
                onEdit={handleUpdateSuccess}
              />
            )}
          </Modal>
          <Modal
            key={selectedCurrency ? selectedCurrency.id : null}
            open={isAddAlertModalOpen}
            onCancel={handleCloseAddAlertModal}
            footer={null}
          >
            {selectedCurrency && (
              <AddAlertForm
                cryptoCurrencyId={selectedCurrency.id}
                athValue={selectedCurrency.ath}
                onClose={handleCloseAddAlertModal}
                onAlertAdded={handleAddAlertSuccess}
                alerts={alerts[selectedCurrency.id] || []}
                isModalOpen={isAddAlertModalOpen}
              />
            )}
          </Modal>
          <Modal
            open={isAddModalOpen}
            onCancel={handleCloseAddModal}
            footer={null}
          >
            <AddCryptoCurrencyForm onAdd={handleAddCurrencySuccess} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default CryptoCurrenciesList;
