import React, { useEffect } from 'react';
import api from '../services/api';
import { Form, Input, Button, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const EditCryptoCurrencyForm = ({ cryptoCurrency, onClose, onEdit }) => {
  const [form] = Form.useForm();

  // Réinitialise les champs du formulaire à chaque ouverture de la modale pour édition
  useEffect(() => {
    form.setFieldsValue({
      symbol: cryptoCurrency.symbol, 
      name: cryptoCurrency.name, 
      averagePurchasePrice: cryptoCurrency.averagePurchasePrice,
      ath: cryptoCurrency.ath,
    });
  }, [cryptoCurrency, form]);

  const handleSubmit = async (values) => {
    // Récupération de l'userId du localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user.userId : null;

    try {
      // Ajoute l'userId dans la requête de mise à jour
      await api.put(`/crypto-currencies/${cryptoCurrency.id}`, {
        ...values,
        averagePurchasePrice: values.averagePurchasePrice ? parseFloat(values.averagePurchasePrice) : cryptoCurrency.averagePurchasePrice,
        ath: values.ath ? parseFloat(values.ath) : cryptoCurrency.ath,
        userId, // Inclus l'userId ici
      });
      onEdit(); // Notification de succès et fermeture du modal
      onClose(); 
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la crypto-monnaie", error);
      Modal.error({
        title: 'Erreur',
        content: 'Une erreur est survenue lors de la mise à jour.',
      });
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <h2>Modifier les Informations</h2>
      <Form.Item
        label="Symbole"
        name="symbol"
        >
        <Input />
        </Form.Item>
        <Form.Item
        label="Nom"
        name="name"
        >
        <Input />
      </Form.Item>
      <Form.Item
        label="Prix d'achat moyen"
        name="averagePurchasePrice"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="All Time High (ATH)"
        name="ath"
        extra={
          <Tooltip title="Consulter CoinMarketCap pour l'ATH">
            <a href={`https://coinmarketcap.com/currencies/${cryptoCurrency.name.toLowerCase()}/`} target="_blank" rel="noopener noreferrer">
              Consulter ATH sur CoinMarketCap <InfoCircleOutlined />
            </a>
          </Tooltip>
        }
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Mettre à jour
        </Button>
        <Button onClick={onClose} style={{ marginLeft: '10px' }}>
          Annuler
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditCryptoCurrencyForm;
