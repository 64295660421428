import React from 'react';
import api from '../services/api';
import { Form, Input, Button, Tooltip, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const AddCryptoCurrencyForm = ({ onAdd }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const { symbol, name, averagePurchasePrice, ath } = values;
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user.userId : null;
      const response = await api.post(`/crypto-currencies`, { 
        symbol, 
        name, 
        averagePurchasePrice: averagePurchasePrice ? parseFloat(averagePurchasePrice) : null,
        ath: ath ? parseFloat(ath) : null,
        userId
      });
      onAdd(response.data);
      form.resetFields();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la crypto-monnaie", error);
    }
  };

  const handleCoinMarketCapLink = () => {
    const name = form.getFieldValue('name');
    if (!name) {
      message.warning('Veuillez d\'abord entrer le nom de la crypto-monnaie.');
      return;
    }
    const url = `https://coinmarketcap.com/currencies/${name.toLowerCase()}/`;
    window.open(url, '_blank');
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <h2>Ajouter une Crypto-Monnaie</h2>
      <Form.Item
        label="Symbole"
        name="symbol"
        rules={[{ required: true, message: 'Veuillez entrer un symbole !' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
        label="Nom"
        name="name"
        rules={[{ required: true, message: 'Veuillez entrer un nom !' }]}
        >
        <Input />
      </Form.Item>
      <Form.Item
        label="Prix d'achat moyen"
        name="averagePurchasePrice"
        rules={[{ required: true, message: 'Veuillez entrer le prix d\'achat moyen !' }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="All Time High (ATH)"
        name="ath"
        rules={[{ required: true, message: 'Veuillez entrer l\'ATH !' }]}
        extra={
          <span>
            Vous pouvez trouver cette information sur 
            <Tooltip title="Cliquez pour plus d'informations sur CoinMarketCap">
              <a onClick={handleCoinMarketCapLink} style={{ marginLeft: 5 }}>CoinMarketCap</a>
            </Tooltip>.
          </span>
        }
      >
        <Input type="number" suffix={<InfoCircleOutlined onClick={handleCoinMarketCapLink} style={{ cursor: 'pointer' }} />} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Ajouter
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCryptoCurrencyForm;
