import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, List, Popconfirm } from 'antd';
import api from '../services/api';

const { Option } = Select;

const AddAlertForm = ({ cryptoCurrencyId, athValue, onClose, onAlertAdded, alerts, isModalOpen }) => {
  const [form] = Form.useForm();
  const [editingAlert, setEditingAlert] = useState(null);

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      setEditingAlert(null);
    }
  }, [isModalOpen, form]);

  const handleSubmit = async (values) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user.userId : null;

    const url = editingAlert ? `/alerts/${editingAlert.id}` : `/alerts`;
    const method = editingAlert ? 'patch' : 'post';

    const payload = {
      ...values,
      cryptoCurrencyId: editingAlert ? undefined : cryptoCurrencyId,
      status: editingAlert ? editingAlert.status : 'ACTIVE',
      userId, // Ajoute l'userId ici
    };

    try {
      const response = await api[method](url, payload);
      message.success(`Alerte ${editingAlert ? 'mise à jour' : 'créée'} avec succès`);
      onAlertAdded();
      form.resetFields();
      setEditingAlert(null);
    } catch (error) {
      console.error(`Erreur lors de ${editingAlert ? 'la mise à jour' : 'l\'ajout'} de l'alerte`, error);
      message.error(`Erreur lors de ${editingAlert ? 'la mise à jour' : 'la création'} de l'alerte`);
    }
  };

  // Modifier une alerte existante
  const handleEdit = (alert) => {
    setEditingAlert(alert);
    form.setFieldsValue({
      alertType: alert.alertType,
      value: alert.value,
      name: alert.name,
    });
  };

  // Désactiver une alerte existante
  const handleDeactivate = async (alertId) => {
    try {
      await api.patch(`/alerts/${alertId}/deactivate`);
      message.success('Alerte désactivée avec succès');
      onAlertAdded(); // Supposons que cette fonction rafraîchit la liste des alertes
    } catch (error) {
      console.error('Erreur lors de la désactivation de l\'alerte', error);
      message.error('Erreur lors de la désactivation de l\'alerte');
    }
  };

  // Activer une alerte désactivée
  const handleActivate = async (alertId) => {
    try {
      await api.patch(`/alerts/${alertId}/activate`);
      message.success('Alerte réactivée avec succès');
      onAlertAdded(); // Supposons que cette fonction rafraîchit la liste des alertes
    } catch (error) {
      console.error('Erreur lors de la réactivation de l\'alerte', error);
      message.error('Erreur lors de la réactivation de l\'alerte');
    }
  };
  
  // Supprimer une alerte existante
  const handleDelete = async (alertId) => {
    try {
      await api.delete(`/alerts/${alertId}`);
      message.success('Alerte supprimée avec succès');
      onAlertAdded(); // Mise à jour de la liste des alertes après suppression
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'alerte', error);
      message.error('Erreur lors de la suppression de l\'alerte');
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <h2>{editingAlert ? 'Modifier' : 'Ajouter'} une Alerte</h2>
      <Form.Item
        label="Type d'alerte"
        name="alertType"
        rules={[{ required: true, message: 'Veuillez sélectionner un type d\'alerte !' }]}
      >
        <Select
          placeholder="Sélectionnez un type"
          onChange={(value) => {
            if (value === "ATH") {
              form.setFieldsValue({ value: athValue });
            }
          }}
        >
          <Option value="ATH">Atteinte de l'ATH</Option>
          <Option value="MULTIPLE">Multiple du prix d'achat moyen</Option>
          <Option value="PRICE">Atteinte d'un prix spécifique</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valeur"
        name="value"
        rules={[{ required: true, message: 'Veuillez entrer une valeur pour l\'alerte !' }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="Nom de l'alerte"
        name="name"
        rules={[{ required: true, message: 'Veuillez nommer votre alerte !' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
      <Button type="primary" htmlType="submit">
          {editingAlert ? 'Mettre à jour' : 'Créer'} l'alerte
        </Button>
        {editingAlert && (
          <Button onClick={() => setEditingAlert(null)} style={{ marginLeft: 8 }}>
            Annuler modification
          </Button>
        )}
      </Form.Item>
      <h3>Alertes actives</h3>
      <List
        size="small"
        bordered
        dataSource={alerts || []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => handleEdit(item)}>
                Modifier
              </Button>,
              item.status !== 'INACTIVE' ? (
                <Button type="link" onClick={() => handleDeactivate(item.id)}>
                  Désactiver
                </Button>
              ) : (
                <Button type="link" onClick={() => handleActivate(item.id)}>
                  Réactiver
                </Button>
              ),
              <Popconfirm title="Êtes-vous sûr de vouloir supprimer cette alerte ?" onConfirm={() => handleDelete(item.id)}>
                <Button type="link" danger>
                  Supprimer
                </Button>
              </Popconfirm>,
            ]}
            style={{ opacity: item.status === 'INACTIVE' ? 0.5 : 1 }}
          >
            <List.Item.Meta
              title={<span style={{ color: item.status === 'TRIGGERED' ? 'red' : 'inherit' }}>{item.name}</span>}
              description={`${item.value} (${item.alertType})`}
            />
          </List.Item>
        )}
      />
    </Form>
  );
};

export default AddAlertForm;
