import React, { useState } from 'react';
import { Button, Space, Typography, Col, Row, Card } from 'antd';

const { Title } = Typography;

const Disconnected = () => {
    return (
        <Row>
            <Col span={22} offset={1}>
                <Title>Vous êtes déconnecté.</Title>
                <Card style={{ padding: 20, marginBottom: 20 }}>
                    <a href='/login'>
                        <Button type="primary">
                            Se connecter
                        </Button>
                    </a>
                </Card>
            </Col>
        </Row>
    );
};

export default Disconnected;