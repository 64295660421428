import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { Form, Input, Button, message, Typography, Col, Row, Card } from 'antd';

const { Title } = Typography;

const Login = () => { 
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const { username, password } = values;
      const response = await api.post('/auth/login', { username, password });
      if(response && response.data) {
        localStorage.setItem('user', JSON.stringify({ 
          username: response.data.username, 
          userId: response.data.userId 
        }));
        message.success('Connexion réussie');
        navigate('/loginok'); // Redirige vers la page indiquée
      } else {
        throw new Error('Réponse invalide du serveur');
      }
    } catch (error) {
      console.error('Échec de la connexion', error);
      message.error('Échec de la connexion');
    }
  };

  return (
    <Row>
      <Col span={22} offset={1}>
        <Title>Connexion</Title>
        <Card style={{ padding: 20, marginBottom: 20 }}>
          <Form form={form} onFinish={handleLogin} layout="vertical" name="loginForm">
            <Form.Item
              name="username"
              label="Nom d'utilisateur"
              rules={[{ required: true, message: 'Veuillez saisir votre nom d’utilisateur' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Mot de passe"
              rules={[{ required: true, message: 'Veuillez saisir votre mot de passe' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Se connecter
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
